import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

const BASE_URL = environment.BASE_URL;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private jwtHelper = new JwtHelperService();
  /**
   *
   * @param http http client to send http requests
   * @param router Object to manage the routing
   */
  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  get token() {
    return localStorage.getItem('token') || '';
  }

  get options() {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    };
    return options;
  }

  /**
   * method that sends the request to the login API
   * @param email_address string that contains the user email
   * @param password string that contains the user password
   */
  login(password: string) {
    return this.http.post(`${BASE_URL}auth/login`, {password}).pipe(
      tap((resp: any) => {
        this.handleAuth(resp);
      })
    );
  }

  /**
   * method that saves the token, user_id and account_id to make requests
   * @param resp object that contains the response data to be saved
   */
  handleAuth(resp: any) {
    localStorage.setItem('token', resp.token);
  }

  /**
   * method that clears the token, user_id and the account_id to close the session and go to the login.
   */
  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/auth/login');
  }

  isAuthenticated(): boolean {
    if ((!this.token && this.token.length < 10) ||
      this.jwtHelper.isTokenExpired(this.token)) {
      return false;
    }
    return true;
  }
}
