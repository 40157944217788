<em>Updated July 1, 2022</em>
<br />
<br />
<p>
  <strong>CETV Now</strong>
  (“CETV,” “we,” “us,” “our”) respects your concerns about privacy and values our relationship with you. This
  Privacy Policy is intended to inform you of our policies and practices regarding our collection, use, and
  disclosure of the information you submit to us and that we collect from you through the
  <a href="https://www.cetvnow.com/" target="_blank">www.cetvnow.com</a>
  and
  <a href="https://advertiser.cetvnow.com/" target="_blank">advertiser.cetvnow.com</a>
  websites (each a “Website” and collectively the “Websites”). This Privacy Policy does not govern information you
  might provide through any channel other than our Websites. This Privacy Policy applies to all visitors to our
  Websites and use of CETV’s services is subject to this policy as well as the Terms of Service (“TOS”) for each
  applicable Website.
</p>
<br />
<p>
  We process any personal data required either to become a host partner and/or join our ad network in order to
  complete your user account, including, but not limited to your first and last name, company name, and email
  address.
</p>
<br />
<p>
  If you contact CETV by messaging us through our Websites, we collect your name and email address, as well as any
  personally identifiable information you elect to provide us in your message
</p>
<p>
  When you contact us directly via postal mail or telephone, we may collect additional data to help us resolve any
  inquiries relating to your account, take any payments, for marketing purposes, questions regarding the website or
  any other queries.
</p>
<br />
<p>
  If we collect payment information, we collect necessary payment details as well as applicable information that may
  include your home or business address.
</p>
<br />
<p>
  We collect and process data about your browsing on our Websites, including the pages you visit and how you
  interact with these pages. If you become a host partner and/or join our ad network, we collect browsing data about
  your access to the dedicated areas of the Websites.
</p>
<br />
<p>
  We may use any information to communicate with you about topics we believe may be of interest to you, where we
  have a legitimate basis for doing so.
</p>
<br />
<h4>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</h4>
<br />
<p>
  When you contact CETV, become a host partner and/or join our ad network through our Websites, we collect the
  personally identifiable information provided, that may include but is not limited to your name, company name,
  address, telephone number and email address.
</p>
<br />
<p>
  When you browse our Websites, we also automatically receive your computer’s internet protocol (IP) address in
  order to provide us with information that helps us learn about your browser and operating system.
</p>
<br />
<p>
  Email Marketing: We may send you emails about our Websites, services, products and other updates that we believe
  may be of interest to you, where we have a legitimate basis for doing so.
</p>
<br />
<h4>SECTION 2 – CONSENT FOR INFORMATION</h4>
<br />
<p>
  When you provide us with personal information through our Websites, become a host partner and/or join our ad
  network, we imply that you consent to our collecting your personal information and using it for that specific
  reason.
</p>
<br />
<p>
  If we ask for your personal information for a secondary reason, such as for marketing purposes, we will either ask
  you directly for your expressed consent, or we will provide you with an opportunity to say no or opt out.
</p>
<br />
<p>
  CETV does not sell any personal information to any third-party. Please see Section 4, “Third-Party Services”, for
  information about how any third-party providers used by us will collect, use and disclose your information.
</p>
<br />
<p>
  You may withdraw your consent for us to contact you at any time for the continued collection, use or disclosure of
  your information by:
</p>
<br />
<p>
  Email:
  <a>info&#64;cetvnow.com</a>
</p>
<br />
<p>Postal Mail:</p>
<p>CETV Now, LLC</p>
<p>3219 E .Camelback Road, Suite 315</p>
<p>Phoenix, Arizona 85108</p>
<p>Phone: (480) 941-3703</p>
<br />
<p>
  You may opt out of our email campaigns by emailing us directly at
  <a>info&#64;cetvnow.com</a>
  .
</p>
<br />
<h4>SECTION 3 - DISCLOSURE</h4>
<br />
<p>We may disclose your personal information if we are required to do so by law.</p>
<br />
<h4>SECTION 4 - THIRD-PARTY SERVICES</h4>
<br />
<p>
  In general, the third-party service providers used by CETV will only collect, use and disclose your information to
  the extent necessary to allow them to perform the services they provide to us.
</p>
<br />
<p>
  Certain third-party service providers such as payment gateways and other payment transaction processors have their
  own privacy policies in respect to the information we are required to provide to them for your purchase-related
  transactions.
</p>
<br />
<p>
  For these third-party service providers, we recommend that you read their privacy policies to understand how your
  personal information is used. We are not responsible for the privacy practices of other websites and encourage you
  to carefully review any third-party service provider privacy policies.
</p>
<br />
<p>
  Please keep in mind that certain third-party service providers may be located in or have facilities geographically
  located within a different jurisdiction than either you or us. If you elect to proceed with a transaction that
  involves the services of a third-party service provider, then your information may become subject to the laws of
  the jurisdiction(s) in which that service provider or its facilities are located.
</p>
<br />
<p>
  For example, if you are located in Canada and your transaction is processed by a payment gateway located in the
  United States, then your personal information used in completing that transaction may be subject to disclosure
  under United States legislation, including the Patriot Act.
</p>
<br />
<p>
  Once you leave our Websites or are redirected to a third-party website or application, you are no longer governed
  by CETV’S Privacy Policy, and CETV is not responsible for the privacy practices or terms of the third-party
  websites.
</p>
<br />
<p>Links:</p>
<br />
<p>
  Some links on our Websites may take you from our Websites to a new webpage that is not part of our Websites. CETV
  is not responsible for the privacy practices of other websites, and we encourage you to carefully review their
  privacy policies.
</p>
<br />
<p>Analytics and Advertisement Services:</p>
<br />
<p>
  Our Websites use the following analytics and advertisement services to help us learn about who visits our website,
  our visitors’ interests, and what pages are being viewed:
</p>
<br />
<ul>
  <li>Google Analytics</li>
</ul>
<br />
<h4>SECTION 5 - SECURITY</h4>
<br />
<p>
  To protect your personal information, we take reasonable precautions and follow industry best practices to make
  sure that your personal information is not inappropriately lost, misused, accessed, disclosed, altered or
  destroyed.
</p>
<br />
<p>
  If you provide us with your credit card information, the information is encrypted using secure socket layer
  technology (SSL) and stored with an AES-256 encryption. Although no method of transmission over the Internet or
  electronic storage is 100% secure, we follow all PCI-DSS requirements and implement additional generally accepted
  industry standards.
</p>
<br />
<h4>SECTION 6 - AGE OF CONSENT</h4>
<br />
<p>
  By using our Websites, you represent that you are at least the age of majority in your state or province of
  residence, or that you are the age of majority in your state or province of residence and you have given us your
  consent to allow any of your minor dependents to use our Websites.
</p>
<br />
<h4>SECTION 7 - CHANGES TO THIS PRIVACY POLICY</h4>
<br />
<p>
  We reserve the right, in our discretion and without notice, to modify this Privacy Policy at any time. You are
  advised to periodically review this Privacy Policy for any changes. Any changes will take effect immediately upon
  its posting indicated at the top of this page when it was most recently updated. Your use of our Websites
  following any such change constitutes your agreement to follow and be bound by the Privacy Policy, as amended.
</p>
<br />
<p>
  If we make material changes to this policy, we will notify you here that it has been updated, so that you are
  aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose
  it.
</p>
<br />
<p>
  If we are acquired or merge with another company, your information may be transferred to the new owners so that we
  may continue to serve you.
</p>
<br />
<h4>SECTION 8 – CONSENT</h4>
<br />
<p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
<br />
<h4>SECTION 9 – NOTICE TO CALIFORNIA RESIDENTS</h4>
<br />
<p>
  Under California Civil Code Section 1798.83, California users are entitled to request information relating to
  whether a business has disclosed personal information to any third parties for the third parties’ direct marketing
  purposes. CETV does not sell or share your personal information with third party companies for their direct
  marketing purposes without consent.
</p>
<br />
<h4>QUESTIONS AND CONTACT INFORMATION</h4>
<br />
<p>
  If you would like to access, correct, amend or delete any personal information, register a complaint, or simply
  want more information, please contact us:
</p>
<br />
<p>
  <a>info&#64;cetvnow.com</a>
  , or by mail at
</p>
<br />
<p>3219 E .Camelback Road Suite 315</p>
<p>Phoenix, Arizona 85108</p>
