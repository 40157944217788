<main class="app-container">
  <app-menu-bar *ngIf="!hideMenus"
    [ngClass]="{ 'responsive-menu': !isPC && !isCollapsed }" >
  </app-menu-bar>
  <div class="content">
    <app-nav-bar *ngIf="!hideMenus">
    </app-nav-bar>
    <section class="main-content" [ngClass]="{'main-padding': !hideMenus}">
      <router-outlet></router-outlet>
    </section>
  </div>

  <div class="responsive-menu-background"
    *ngIf="!isPC && !isCollapsed"
    (click)="layoutController.setMenuState(true)">
  </div>
</main>

<app-footer *ngIf="hideMenus"></app-footer>

<!-- loader dialog -->
<app-loader-dialog></app-loader-dialog>
