import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutController {
  private menuStateSubject = new BehaviorSubject<boolean>(false);
  menuState$ = this.menuStateSubject.asObservable();

  /**
   * Change the state of the menu sidebar
   * @param isCollapsed handle the menu view -> true menu is hidden, otherwise menu is visible
   */
  setMenuState(isCollapsed: boolean): void {
    this.menuStateSubject.next(isCollapsed);
  }
}
