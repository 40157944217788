import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { menuItems } from '../../consts/menu-items';
import { LayoutController } from '../../services/layout.controller';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit, OnDestroy {
  asideMenu: MenuItem[] = menuItems;
  isCollapsed: boolean = false;
  isPC: boolean = false;
  private menuStateSubscription!: Subscription;

  constructor(private layoutController: LayoutController) {}

  ngOnInit(): void {
    this.menuStateSubscription = this.layoutController.menuState$.subscribe(value => {
      this.isCollapsed = value;
    });
  }

  handleMenuView() {
    this.isCollapsed = !this.isCollapsed;
    this.layoutController.setMenuState(this.isCollapsed);
  }

  ngOnDestroy(): void {
    // Unsubscribe for avoiding memory leaks
    if (this.menuStateSubscription) {
      this.menuStateSubscription.unsubscribe();
    }
  }
}
