import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  navbarMenu: MenuItem[] =[
    {
      label:'ACCOUNT',
      icon:'pi pi-user',
      disabled: true,
      items:[
          {
              label:'My Account',
          },
          {
              label:'Team Management',
          },
        ]
    },
    {
      label: 'LOGOUT',
      icon:'pi pi-sign-out',
      command: () => this.onLogout()
    }
  ];

  constructor(
    private authService: AuthService
  ) {/**/}

  onLogout() {
    this.authService.logout();
  }
}
