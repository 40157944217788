import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { PrivacyPolicyComponent } from '../../company-policies/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from '../../company-policies/terms-conditions/terms-conditions.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [DialogService]
})
export class FooterComponent {
  constructor(
    public dialog: DialogService,
  ) {/**/}
  showLegalPolicies() {
    this.dialog.open(PrivacyPolicyComponent, {
      header: 'PRIVACY POLICY',
      width: '60rem',
      styleClass: 'max-width-90',
      closeOnEscape: false,
      baseZIndex: 10,
      closable: true,
    });
  }

  showTermsOfService() {
    const ref = this.dialog.open(TermsConditionsComponent, {
      header: 'TERMS OF SERVICE',
      width: '60rem',
      styleClass: 'max-width-90',
      closeOnEscape: false,
      baseZIndex: 10,
      closable: true,
    });
  
    ref.onClose.subscribe((response) => {
      console.log(response)
    });

  }

  goToCETV() {
    
  }
}
