import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule } from './core/footer/footer.module';
import { NavbarModule } from './core/navbar/navbar.module';
import { MenuBarModule } from './core/menu-bar/menu-bar.module';
import { AlertModule } from "./shared/components/alert/alert.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// primeNg
import {PrimeNGConfig} from 'primeng/api';
import { PrimeNGModule } from './core/prime-ng/prime-ng.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PrimeNGModule,
    FooterModule,
    BrowserAnimationsModule,
    NavbarModule,
    MenuBarModule,
    HttpClientModule,
    AlertModule
],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private primengConfig: PrimeNGConfig) {
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
