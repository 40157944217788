import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayoutController } from './core/services/layout.controller';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    '(window:resize)': 'onWindowResize($event)',
  },
})
export class AppComponent implements OnInit, OnDestroy {
  isPC: boolean = true;
  isCollapsed: boolean = false;
  private menuStateSubscription!: Subscription;

  constructor(public layoutController: LayoutController) { /**/ }

  ngOnInit(): void {
    this.onWindowResize();
    this.menuStateSubscription = this.layoutController.menuState$.subscribe(value => {
      this.isCollapsed = value;
    });
  }

  get hideMenus() {
    const path = window.location.pathname.split('/')[1]
    return path === 'auth';
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.isPC = innerWidth > 1200;

    if (this.isPC) {
      this.layoutController.setMenuState(false);
    } else {
      this.layoutController.setMenuState(true);
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe for avoiding memory leaks
    if (this.menuStateSubscription) {
      this.menuStateSubscription.unsubscribe();
    }
  }
}
