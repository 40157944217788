import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AlertController {
  /** Control for showing the alert dialog or not */
  loading = false;

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }
}
