import {MenuItem} from 'primeng/api';

export const menuItems: MenuItem[] = [
  {
    label: '',
    items: [
      {label: 'DASHBOARD', icon: 'pi pi-home', routerLink: '/dashboard'},
      {label: 'SCREENS', icon: 'pi pi-desktop', routerLink: '/screens'},
      {label: 'CONTENT', icon: 'pi pi-play-circle', routerLink: '/content'} //TODO: organize this
    ],
  },
  /**{
    label: 'MEDIA AND CONTENT',
    items: [
      {label: 'CONTENT', icon: 'pi pi-calendar-plus', routerLink: '/content'},
      //{label: 'SCHEDULE CONTENT', icon: 'pi pi-calendar-plus', routerLink: '/content/new-content'},
    ],
  }*/
];
