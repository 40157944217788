<footer>
  <div class="flex gap-20 font-weight-600">
    <a (click)="showLegalPolicies()">PRIVACY POLICY</a>
    <a (click)="showTermsOfService()">TERMS & CONDITIONS</a>
  </div>

  <div class="flex align-items-center gap-10">
    <span class="font-weight-600">Powered by</span>
    <img src="assets/images/CETV-logo.png" alt="logo"
      height="42px" class="pointer" (click)="goToCETV()" />
  </div>
</footer>
