<aside class="menu-bar" [ngClass]="{'collapsed': isCollapsed}">
  <div class="header">
    <div class="icon-container">
      <em [ngClass]="isCollapsed ? 'rotate' : 'no-rotate'" class="bx bxs-chevron-left"
        (click)="handleMenuView()"
      ></em>
    </div>
    <div class="logo-container">
      <img src="/assets/images/CETV-logo.png" alt="Logo" />
    </div>
  </div>
  <p-menu [model]="asideMenu" [style]="{'width': isCollapsed ? '70px' : '250px'}"></p-menu>
</aside>
