import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderDialogComponent } from './components/loader-dialog/loader-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { EmptyMessageComponent } from './components/empty-message/empty-message.component';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [
    LoaderComponent,
    LoaderDialogComponent,
    EmptyMessageComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    RouterModule
  ],
  exports: [
    LoaderComponent,
    LoaderDialogComponent,
    EmptyMessageComponent
  ]
})
export class AlertModule { }
