<em>Last updated May 9, 2024</em>
<br />
<p>
  Advertiser.CETVNow.com is a feature of CETV Now, LLC, an Arizona limited liability company (“CETV”, “we”, “us”, “our”) 
  that enables our customers (“you”, “your”) to create and launch advertisement campaigns to be displayed in commercial 
  environments and to control the settings of advertisement insertions (each, a “Service”, and collectively, the 
  “Services”), allowing you to reinforce your brand or product through targeted video and photo advertisements 
  (collectively, the “CETV Ad Network”). 
</p>
<p>
  These Terms of Service (“TOS”) govern your access to and use of the CETV Ad Network and the CETV Ad Network website, 
  located at
  <a href="https://www.cetvnow.com/" target="_blank">www.cetvnow.com</a> (the “Website”). 
  These TOS govern all users of the CETV Ad Network and the Website, including but not limited to those who signed up 
  for our Services directly with us and those who were engaged by a third party reseller in connection with our Services. 
  By accessing or using the CETV Ad Network and Website, you hereby consent to and agree to these TOS and conclude a 
  legally binding contract with CETV. 
</p>
<p>
  Please read these TOS carefully and make sure that you understand them. Unless otherwise stated, these TOS apply to 
  the CETV Ad Network Services and Website and no other CETV products. You may not access or use the CETV Ad Network 
  or Website if you are unwilling or unable to be bound by any part of these TOS. Separate terms of service apply for 
  other CETV products. 
</p>
<h3>CETV Ad Network</h3>
<strong>What We Offer You</strong>
<p>
  The CETV Ad Network, designed specifically for advertisers, allows you to create and launch advertisement campaigns 
  (i) with a defined starting date (“Campaign Start Date”) and a defined end date (“Campaign End Date” (each such 
  campaign, a “Single Advertisement Campaign”)), or (ii) in a pre-selected number of consecutive thirty (30) day periods 
  (each 30 day period, a “Cycle”) that is billed on the first day of each Cycle (each such campaign, a “Recurring 
  Advertisement Campaign”, with the Single Advertisement Campaign, an “Advertisement Campaign”, collectively, the 
  “Advertisement Campaigns”). Each Advertisement Campaign allows you to select the settings for your advertisement 
  insertions, allowing you to reinforce your brand or product through targeted video or static image advertisements. 
</p>
<p>
  CETV strives to support a healthy digital advertising system that works for users, advertisers and publishers. 
  Through the CETV Ad Network, you can: 
</p>
<ul>
  <li>
    Promote your company brand or products across multiple locations and through multiple business sector
    categories throughout various national media markets.
  </li>
  <li>Create unique Advertisement Campaigns to fit your specific needs.</li>
  <li>
    Customize your Advertisement Campaign with features such as pre-selected geographic locations, selected 
    budgets, a Campaign Start Date and Campaign End Date for a Single Advertisement Campaign, and a pre-selected 
    number of Cycles for a Recurring Advertisement Campaign.
  </li>
  <li>
    Design personalized Advertisement Campaigns schedules targeting your desired audiences, including 
    neighboring businesses.
  </li>
  <li>
    Micro target your Advertisement Campaigns by promoting your advertisements to specific demographics 
    based on your desired audiences.
  </li>
  <li>
    Monitor your Advertisement Campaigns and promote your products and services in real time as customers 
    are buying.
  </li>
</ul>

<p>
  At the end of your applicable Advertisement Campaign, CETV provides you with a fulfillment report in CETV’s 
  standard format then in existence. 
</p>
<br />
<strong>Your Acknowledgements and Responsibilities of Using the CETV Ad Network Services</strong>
<p>By advertising through the CETV Ad Network, you understand these TOS and agree to be bound by them.</p>
<p>
  Use of the CETV Ad Network requires MP4 files for video upload and JPEG or PNG files for static image uploads. 
  Certain file size and video length restrictions apply. 
</p>
<p>
  You understand and consent that CETV may, at its sole discretion, deem any advertisement through the CETV Ad 
  Network in violation of these TOS, including but not limited to improper file size and or video length for your 
  advertisement. If a violation is found, the advertisement (including a then current Advertisement Campaign) may 
  be terminated by CETV. 
</p>
<p>
  You understand that advertisements, including all current Advertisement Campaigns, remain subject to review and 
  re-review at all times by CETV. It is your responsibility to understand and comply with these TOS. 
</p>
<br />
<h3>Intellectual Property</h3>
<p>
  CETV Now is committed to helping people and entities protect their intellectual property rights. These TOS 
  prohibit posting content through the CETV Ad Network or Website that violates someone else’s intellectual property 
  rights, including but not limited to copyright, trademark and patent rights. 
</p>
<p>
  You should only upload content that you have made, including but not limited to copyrights and trademarks, or that 
  you have express written permission to use (collectively, and for the purposes of these TOS, “Your Intellectual 
  Property”). That means that you may not upload any content for Advertisement Campaigns used through the CETV Ad 
  Network or Website that you did not create or that you do not have express written permission to use. 
</p>
<br />
<h3>CETV Rights to Your Intellectual Property</h3>
<p>
  By uploading Your Intellectual Property through the CETV Ad Network or Website, you hereby grant CETV a nonexclusive, 
  royalty free, worldwide and perpetual license to display Your Intellectual Property through our Services for the 
  Advertisement Campaign defined by you with respect to the uploaded content. Further, by uploading Your Intellectual 
  Property through the CETV Ad Network or Website, you hereby grant CETV a nonexclusive, royalty free, worldwide and 
  perpetual license to modify and/or transcode Your Intellectual Property to the extent needed to properly format for 
  our Services. Notwithstanding the immediately preceding sentence, any modifications or transcoding to Your Intellectual 
  Property by CETV are exclusively for the period defined by your Advertisement Campaign with respect to the uploaded 
  content.
</p>
<br />
<h3>Your Rights to CETV Intellectual Property</h3>
<br />
<p>
  In certain circumstances and upon your request, CETV may provide you with content created by CETV to upload on the 
  CETV Ad Network or Website to use during your Advertisement Campaign (such content, the “CETV Creative”). In such 
  circumstance or circumstances, CETV grants to you, during the applicable Advertisement Campaign, a nonexclusive, 
  nontransferable, non-sublicensable, royalty-free, worldwide right and license to use the CETV Creative through the CETV 
  Ad Network or Website. At the end of your Advertisement Campaign, provided that CETV is in receipt of all payments 
  associated with your Advertisement Campaign, all intellectual property rights, title and interest owned by CETV in the 
  CETV Creative created and published on the CETV Ad Network or Website pursuant to the applicable Advertisement Campaign 
  shall irrevocably transfer to you, at which time you shall then become the sole owner of all such content. For purposes 
  of clarification CETV Creative shall not include any of Your Intellectual Property that is combined and included with 
  the CETV Creative.
</p>
<br />
<h3>Subscription Terms and Refunds</h3>
<p>
  To use the Services on the CETV Ad Network, you must first create an account through our Website and build your 
  Advertisement Campaign, which includes a Campaign Start Date and Campaign End Date for your Single Advertisement 
  Campaign, the number of Cycles selected for your Recurring Advertisement Campaign, a budget for your Advertisement 
  Campaign, business industries that your Advertisement Campaign will target and the geographic location or locations 
  in which your Advertisement Campaign will run. Payment is based exclusively on the Advertisement Campaign or 
  Advertisement Campaigns that you create.
</p>
<p>
  For a Single Advertisement Campaign, your budget can be set either (1) per day or (2) per Single Advertisement Campaign, 
  set by you at the time you create your Single Advertisement Campaign on the Website, with payment due at checkout at the 
  time the Single Advertisement Campaign is created.
</p>
<p>
  For a Recurring Advertisement Campaign, your budget can be set (1) per day or (2) per Cycle, set by you at the time you 
  create your Recuring Advertisement Campaign on the Website. Payment for the first Cycle is due at checkout at the time 
  the Recurring Advertisement Campaign is created. 
</p>
<p>
  Provided that CETV fulfills its obligations pursuant to these TOS, payment for each subsequent Cycle is due on the first 
  day of the subsequent Cycle.
</p>
<p>Any user may run multiple Advertisement Campaigns concurrently.</p>
<p>
  You authorize CETV to run the credit card associated with your account for your Advertisement Campaign payment pursuant 
  to the campaign budget option selected. For any Recurring Advertisement Campaign, you authorize CETV to run the credit 
  card associated with your account on the first day of each Cycle during your Recurring Advertisement Campaign. 
</p>
<br />
<h3>Use of Passwords</h3>
<p>
  Access to certain portions of our Website (for example, your account), may involve the use of a unique user name and/or 
  password. You are solely responsible for maintaining the security of such user name(s) and password(s), and will 
  promptly notify us if you believe such security has been compromised. You will not use the user name(s) or password(s) 
  of any third party without such party’s express prior consent. 
</p>
<br />
<h3>Prohibited Uses</h3>
<p>You may not use the CETV Ad Network or Website for an Advertisement Campaign or to transmit any material that:</p>
<ul>
  <li>
    Contains any obscene material or images of nudity, or describes, depicts or represents explicit sexual activities.
  </li>
  <li>
    Discriminates or encourages discrimination against people based on personal and protected characteristics or 
    attributes including, but not limited to race, ethnicity, color, national origin, religion, age, sex, sexual 
    orientation, gender identity and disability.
  </li>
  <li>
    Promotes or encourages the use of illegal drugs, tobacco products, tobacco-related products, and products that 
    simulate smoking or are modeled on tobacco products, including but not limited to cigarettes, cigars, vape and 
    smokeless tobacco.
  </li>
  <li>Constitutes, facilitates or promotes any other illegal products, services or activities.</li>
  <li>Promotes or encourages the use of firearms.</li>
  <li>
    Infringes on the intellectual property rights of any third party, including but not limited to trademarks or 
    copyrights of another.
  </li>
</ul>

<br />
<h3>Indemnity</h3>
<p>
  You agree to indemnify, defend, and hold harmless CETV, its members, officers, employees, agents, representatives, 
  suppliers, and content and service providers from and against any and all losses, expenses, damages, and costs, 
  including reasonable attorneys fees, resulting from any violation of these TOS or any activity related to your use of 
  the CETV Ad Network or Website by you or any other person accessing the CETV Ad Network or Website.
</p>
<br />
<h3>Your Information</h3>
<p>
  Our Privacy Policy explains how we treat your personal data and protect your privacy when you use the CETV Ad 
  Network and Website. 
</p>
<br />
<h3>Applicable Law</h3>
<p>
  Your use of the CETV Ad Network and Website shall be governed by the laws of the State of Arizona without regard to 
  its choice of law principles. You agree to submit to the personal jurisdiction of the state and federal courts located 
  in Maricopa County, Arizona with respect to any legal proceedings arising out of these TOS or your use of the CETV Ad 
  Network and Website. 
</p>
<br />
<h3>Age of Consent</h3>
<p>
  By using the CETV Ad Network and Website, you represent that you are at least the age of majority in your state of 
  residence, or that you are the age of majority in your state of residence and you have given us your consent to allow 
  any minors within your direct control to use the CETV Ad Network and Website. 
</p>
<br />
<h3>Changes to these Terms of Service</h3>
<p>
  These TOS were posted on the date set forth above. We reserve the right, in our discretion and without notice, to 
  modify these TOS at any time. You are advised to periodically review these TOS for any changes. Any such changes will 
  take effect immediately upon its posting on the Website. Your use of the CETV Ad Network and Website following any 
  such change constitutes your agreement to follow and be bound by the TOS, as amended.
</p>
<br />
<h3>Changes to the CETV Ad Network</h3>
<p>
  CETV reserves the right to make additions, deletions or modifications to the CETV Ad Network and Website, in our 
  discretion and without notice at any time.
</p>
<br />
<h3>Contact Us</h3>
<p>
  If you have any questions about these TOS, please contact us. We will be happy to answer any questions or concerns 
  you might have.
</p>
<p>
  CETV Now, LLC
  <br />
  3219 E. Camelback Road, Suite 315
  <br />
  Suite 315
  <br />
  Phoenix, Arizona 85108
  <br />
  (480) 941-3703
  <br />
  <a href="mailto:legal@cetvnow.com">legal&#64;cetvnow.com</a>
</p>
